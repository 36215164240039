const routes = [
    {
        name: 'Home',
        path: '/',
        component: () => import('../view/Home.vue'),
    },
    {
        name: 'NationalCraft',
        path: '/NationalCraft',
        component: () => import('../view/NationalCraft.vue')
    },
    {
        name: 'RegionalCraft',
        path: '/RegionalCraft',
        component: () => import('../view/RegionalCraft.vue')
    },
    {
        name: 'HandicraftMap',
        path: '/HandicraftMap',
        component: () => import('../view/HandicraftMap.vue')
    },
    {
        name: 'DistributionMap',
        path: '/DistributionMap',
        component: () => import('../view/DistributionMap.vue')
    },
    {
        name: 'BrocadeInformation',
        path: '/BrocadeInformation',
        component: () => import('../view/BrocadeInformation.vue')
    },
    {
        name: 'craftInformation',
        path: '/craftInformation',
        component: () => import('../view/craftInformation.vue')
    },
    {
        name: 'Search',
        path: '/Search',
        component: ()=>import('../view/Search.vue')
    }
];

export default routes
