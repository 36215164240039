import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import locale from "element-plus/lib/locale/lang/zh-cn"
import 'element-plus/dist/index.css'
// import 'element-plus/theme-chalk/index.css'
import axios from 'axios'
import router from './router/index'
const app = createApp(App)
import scroll from 'vue-seamless-scroll/src'
app.use(ElementPlus, { size: 'small', zIndex: 3000,locale})
axios.defaults.baseURL = '/nfss'

app.mount('#app')
createApp(App).use(router).use(scroll).mount('#app')
